<template>
  <div v-if="content" class="rte" v-html="content"></div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();
const currentInstance = getCurrentInstance();

const props = defineProps({
  content: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  if (currentInstance?.vnode?.el?.innerHTML) {
    const links = currentInstance?.vnode?.el.querySelectorAll("[href^='#']");
    links.forEach((item) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        const url = new URL(location);
        url.hash = item.hash;
        history.replaceState(null, null, url);
        if (document.querySelector(item.hash)) {
          window.scrollTo({
            top: document.querySelector(item.hash).offsetTop - 100,
            behavior: "smooth",
          });
        }
      });
    });
  }
});
</script>

<style lang="scss">
.rte {
  * {
    font-family: $font-family !important;
  }

  p {
    margin-bottom: $spacing-base;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  a:not(.btn),
  a:not(.btn) span {
    color: $color-black !important;
    font-weight: $font-bold !important;
  }

  ul {
    list-style-type: disc;
    margin-top: $spacing-base;
    margin-bottom: $spacing-base;
    padding-left: $spacing-base;
  }

  ol {
    margin-top: $spacing-base;
    margin-bottom: $spacing-base;
    padding-left: 1.75rem;
    list-style-type: decimal;
  }

  li {
    margin-bottom: $spacing-xs;
  }

  blockquote {
    font-style: italic;
    margin-top: $spacing-base;
    margin-bottom: $spacing-base;
    line-height: 1.625;
  }

  //Table

  .table {
    width: 100%;
    overflow-x: auto;
    display: block;

    table {
      width: 100%;
    }
  }

  thead {
    background-color: $color-beige;
  }

  th {
    padding: $spacing-xs;
    text-align: left;
  }

  tr {
    &:nth-child(even) {
      background: $color-light-gray;
    }
  }

  td {
    padding: $spacing-xs;
  }

  //Button

  .btn {
    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
  }
}
</style>
